import React from 'react';
import CookiesView from 'ToolboxComponents/webapp-light/pages/cookies-view/cookies-view';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';


const Cookies = props => {
  useIsContentLoaded(true);
  return <CookiesView />;
}

export default Cookies;
