import React, { useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';

import { CookiesViewWrapper } from 'ToolboxComponents/webapp/pages/cookies-view/cookies-view.styles';

const CookiesView = () => {
  const theme = useContext(ThemeContext).state;
  return (
    <CookiesViewWrapper theme={theme} className='container'>
      <h2>Politique de gestion des cookies</h2>

      <br />

      <p>
        En naviguant sur notre site internet ou notre application mobile, les utilisateurs sont identifiés et leur navigation est suivie afin notamment de leur proposer des publicités ciblées ou des services personnalisés. Ce traçage est réalisé par l'intermédiaire de différentes technologies, dont la plus répandue est aujourd'hui celle des cookies.
        Un cookie est un petit fichier déposé sur l’ordinateur de l’Utilisateur pour une durée limitée lorsque celui-ci navigue sur un site. Les informations liées à la navigation sont alors stockées à cet endroit. Ces cookies permettent donc d’identifier l’Utilisateur à chacune de ses visites et ainsi aident à l'amélioration de l’expérience utilisateur en proposant à celui-ci des services encore plus appropriés à ses besoins.
      </p>

      <br /><br />

      <p className='bold'>Il existe différents types de cookies :</p>

      <br />

      <div className="pl-4">

        <p className='bold'>Les cookies analytiques/mesure d’audience</p>
        <p>
          Ces cookies nous permettent d'obtenir des informations quant à la fréquentation de notre site et d'analyser les données reliées au trafic. Ces statistiques de fréquentation nous permettent notamment de détecter des problèmes de navigation et ainsi d'optimiser la navigation de nos utilisateurs.
        </p>

        <br />

        <p className='bold'>Les cookies traceurs</p>
        <p>
          Sont concernés les cookies qui permettent de suivre et « tracer » le comportement et la navigation des utilisateurs. Ils sont notamment déposés et lus lors de la consultation du site internet par l’utilisateur, mais également au moment de la lecture d'un courrier électronique, de l'installation ou de l'utilisation d'un logiciel ou d'une application mobile et ce, quel que soit le type de terminal utilisé tels qu'un ordinateur, un Smartphone.
        </p>

        <br />

        <p className='bold'>Les cookies techniques</p>
        <p>
          Ces cookies nous permettent de présenter aux utilisateurs des services adaptés à leur navigation, comme par exemple la possibilité pour l’utilisateur de se connecter au moyen de son compte Facebook. Ces cookies permettent d'améliorer l’expérience de l’utilisateur.
        </p>

        <br />

      </div>

      <p className='bold'>Principe d’information et de consentement avant l’installation de cookies</p>

      <br />

      <p>
        Le législateur européen a posé le principe d’obtention du consentement des utilisateurs AVANT d’installer certains cookies. Ce consentement préalable de l’utilisateur, selon la Cnil, peut consister en la poursuite de la navigation après avoir lu le « bandeau cookies » sur la page d’accueil.
      </p>

      <br />

      <p>
        A noter : il n’est pas nécessaire d’obtenir le consentement des utilisateurs lorsque les cookies sont « techniques » c’est-à-dire qu’ils sont strictement nécessaires pour la délivrance d'un service expressément demandé par l'utilisateur.
      </p>

      <br />

      <p className='bold'>Durée de conservation des cookies</p>

      <br />

      <p>
        Conformément aux recommandations de la Cnil, le consentement pour la collecte des données des utilisateurs par les cookies ainsi que les données collectées sont conservées treize mois.
      </p>

      <br />

      <p>Sur la Webapp et/ou sur l’application mobile, 2 types de cookies sont utilisés :</p>

      <br />

      <table>
        <thead>
          <tr>
            <td colSpan="3" ><h3>COOKIES</h3></td>
          </tr>
        </thead>
        <thead>
          <tr>
            <td><h3>NOM</h3></td>
            <td><h3>FINALITE (traceur, techniques, publicitaire, mesures d’audience)</h3></td>
            <td><h3>DUREE</h3></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><p>Google analytics</p></td>
            <td><p>Mesure d’audience</p></td>
            <td><p>1 SEMAINE</p></td>
          </tr>
          <tr>
            <td><p>Facebook Connect</p></td>
            <td>
              <p>Technique (authentification, sécurité, performance)<br />
              Mesure d’audience</p>
            </td>
            <td><p>1 SEMAINE</p></td>
          </tr>
          <tr>
            <td><p>Langue</p></td>
            <td><p>Technique (Langue)</p></td>
            <td><p>Jusqu’au prochain changement de langue</p></td>
          </tr>
          <tr>
            <td><p>Acceptation cookie</p></td>
            <td><p>Technique (Acceptation cookies)</p></td>
            <td><p>1 SEMAINE</p></td>
          </tr>
          <tr>
            <td><p>Liste résultat</p></td>
            <td><p>Technique (Dernière liste de résultats)</p></td>
            <td><p>Les résultats de recherche sont mis à jour après chaque recherche.</p></td>
          </tr>
          <tr>
            <td><p>Recherche</p></td>
            <td><p>Technique (Dernière recherche)</p></td>
            <td><p>La recherche est mise à jour à chaque nouvelle recherche</p></td>
          </tr>
          <tr>
            <td><p>Utilisateur</p></td>
            <td><p>Technique (Dernier utilisateur connecté)</p></td>
            <td><p>Initialisé à la connexion d’un utilisateur. Supprimé à la déconnexion</p></td>
          </tr>
          <tr>
            <td><p>Favoris</p></td>
            <td><p>Technique (Mise en favoris)</p></td>
            <td><p>Modifié à chaque ajout ou suppression d’un produit</p></td>
          </tr>
        </tbody>
      </table>

      <br />

      <p className='bold'>Comment s’opposer à l'enregistrement des cookies ?</p>

      <br />

      <p>
        L’Utilisateur peut s’opposer à l'enregistrement de cookies en configurant son navigateur de la manière suivante :
      </p>

      <br />

      <p><u>Pour Google Chrome :</u></p>
      <ol>
        <li><p>Choisissez le menu "Paramètres" puis cliquez sur "Afficher les paramètres avancés"</p></li>
        <li><p>Cliquez sur le bouton "Paramètres de contenu" dans la rubrique "Confidentialité"</p></li>
        <li><p>Dans la rubrique "Cookie", sélectionnez les options qui vous conviennent</p></li>
      </ol>
      <p>Plus d’informations dans <a target="_blank" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=fr&oco=1">l’aide de Chrome</a></p>

      <br />

      <p><u>Pour Microsoft Internet Explorer :</u></p>
      <ol>
        <li><p>Choisissez le menu "Outils" puis "Options Internet"</p></li>
        <li><p>Cliquez sur l'onglet "Confidentialité"</p></li>
        <li><p>Sélectionnez le niveau souhaité à l'aide du curseur.</p></li>
      </ol>
      <p>Plus d’informations dans <a target="_blank" href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies">l’aide de Microsoft Internet Explorer</a></p>

      <br />

      <p><u>Pour Mozilla Firefox :</u></p>
      <ol>
        <li><p>Cliquez sur le bouton de menu et sélectionnez "Préférences"</p></li>
        <li><p>Sélectionnez le panneau "Vie privée et sécurité"</p></li>
        <li><p>Dans le menu "Règles de conservation", sélectionnez les options qui vous conviennent.</p></li>
      </ol>
      <p>Plus d’informations dans <a target="_blank" href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences?redirectlocale=fr&redirectslug=Activer+et+d%C3%A9sactiver+les+cookies#w_comment-modifier-les-paramaetres-des-cookiesa">l’aide de Mozilla Firefox</a></p>

      <br />

      <p><u>Pour Opera 6 et au-delà :</u></p>
      <ol>
        <li><p>Choisissez le menu "Fichier" > "Préférences" > "Avancé" > "Cookies"</p></li>
        <li><p>Choisissez l’option qui vous convient</p></li>
      </ol>
      <p>Plus d’informations dans <a target="_blank" href="https://help.opera.com/Windows/10.20/fr/cookies.html">l’aide d’Opera</a></p>

      <br />

      <p><u>Pour Safari :</u></p>
      <ol>
        <li><p>Choisissez le menu "Safari" > "Préférences"</p></li>
        <li><p>Cliquez sur "Confidentialité"</p></li>
        <li><p>Choisissez l’option qui vous convient</p></li>
      </ol>
      <p>Plus d’informations dans <a target="_blank" href="https://support.apple.com/kb/ph21411?locale=fr_CA">l’aide de Safari</a></p>

      <br />

      <p className='bold'>Plus d'information sur les cookies</p>

      <br />

      <p>Sur le site de la CNIL : <a target="_blank" href="https://www.cnil.fr/vos-droits/vos-traces/les-cookies/">www.cnil.fr/vos-droits/vos-traces/les-cookies</a></p>
    </CookiesViewWrapper>
  );
}

export default CookiesView;
